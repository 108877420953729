
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthGuard implements CanActivate {

  redirectUrl;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  // Function to check if user is authorized to view route
  canActivate(
    router: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    let authStatus = this.authService.loggedIn(state.url)
    // console.log('authStatus-----', authStatus);

    // Check if user is logge din
    if (authStatus === "true") {
      // console.log("in authguard --------true---------");
      return true; // Return true: User is allowed to view route
    } else if (authStatus === "Resticted") {
      // console.log("in authguard --------Resticted---------");

      this.redirectUrl = state.url; // Grab previous urul
      // this.router.navigate(['/gym/members']); // Return error and route to login page
      this.router.navigate(['/services-restriction']); // Return error and route to services restriction page
    } else {
      // console.log("in authguard --------unauthorised---------");
      this.redirectUrl = state.url; // Grab previous urul
      this.router.navigate(['/login']); // Return error and route to login page
      return false; // Return false: user not authorized to view page
    }
  }
}
