import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { throwError } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
// import { tokenNotExpired } from 'angular2-jwt';
import { Router } from "@angular/router";

@Injectable()
export class AuthService {
  authToken: any;
  user: any;
  userData: any;

  constructor(public snackbar: MatSnackBar, public router: Router) {}

  // Function to create headers, add token, to be used in HTTP requests
  createAuthenticationHeaders() {
    const token = this.loadToken();
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "authorization": token
      }),
    };
    httpOptions;
  }

  createHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return httpOptions;
  }

  createAuthorizationHeader(headers: HttpHeaders) {
    headers.append("Authorization", this.loadToken());
  }

  // Function to get token from client local storage
  loadToken() {
    this.authToken = localStorage.getItem("token");
    return this.authToken;
  }

  loadUserData() {
    this.userData = localStorage.getItem("userDetails");
    return this.userData;
  }

  // Function to logout
  logout() {
    localStorage.clear();
    this.router.navigate(["/login"]);
  }

  // Function to logout
  adminLogout() {
    localStorage.clear();
    this.router.navigate(["/admin-login"]);
  }

  // Function to store user's data in client local storage
  storeUserData(token, userData) {
    localStorage.setItem("token", token);
    localStorage.setItem("userDetails", JSON.stringify(userData));
    localStorage.setItem(
      "accessRestriction",
      JSON.stringify(userData.accessRestriction)
    );
    localStorage.setItem(
      "dashboardAccessRestriction",
      userData.dashboardAccessRestriction
    );
    localStorage.setItem("userIsLoggedIn", "yes");
  }

  // Function to check if user is logged in
  loggedIn(currentUrl) {
    // console.log('currentUrl', currentUrl);

    // get the auth token from localStorage
    let token = localStorage.getItem("token");
    let accessList = localStorage.getItem("accessList");
    let accessRestriction = localStorage.getItem("accessRestriction");
    let dashboardAccessRestriction = localStorage.getItem(
      "dashboardAccessRestriction"
    );
    // console.log('accessList', accessList);
    // console.log('accessRestriction', accessRestriction);
    // console.log('dashboardAccessRestriction', dashboardAccessRestriction);

    if (accessList && accessList.length > 0 && accessList.includes(currentUrl))
      return "Resticted";
    if (accessRestriction && accessRestriction.includes(currentUrl))
      return "Resticted";
    if (
      dashboardAccessRestriction &&
      dashboardAccessRestriction.includes(currentUrl)
    )
      return "Resticted";

    // check if token is set, then...
    if (token) {
      return "true";
    }
    return "false";
    // return "false";
  }

  getCheckError(error: any) {
    // var errMsg = "Warning - ";
    // this.snackbar.open(errMsg, error.Message, { duration: 3000 });
    if (error.status === 401) {
      this.logout();
      return throwError(false);
    } else if (error.status === 403) {
      this.snackbar.open(
        "Warning",
        "You are not authorised to perform this action.",
        { duration: 3000 }
      );
    } else {
      const errMsg = error.message
        ? error.message
        : error.status
        ? `${error.status} - ${error.statusText}`
        : "Server error";
      // console.error(errMsg);
      return throwError(errMsg);
    }
  }
}
