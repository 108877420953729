import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
@Component({
  selector: 'fyt-restriction-popup',
  templateUrl: './restriction-popup.component.html',
  styleUrls: ['./restriction-popup.component.css']
})
export class RestrictionPopupComponent implements OnInit, OnChanges {
  @Input() InutDataObj;
  @Output() onActon = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
}
  closeDialog(flag){
    this.onActon.emit({ flag: flag, page: 'restriction' });
  }

}
