import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared-modules/services/auth.service';
import { SubExpiredService } from '../sub-expired.service';

@Component({
  selector: 'fyt-sub-expired-popup',
  templateUrl: './sub-expired-popup.component.html',
  styleUrls: ['./sub-expired-popup.component.css']
})
export class SubExpiredPopupComponent implements OnInit {
  @Input() InputObj;
  @Output() OutputObj1 = new EventEmitter<any>();
  renewPlan: boolean = true;
  paydata: any;
  renewPlanData: any;
  url: any;
  loader: boolean = false;
  onlinePayFlag: boolean = false;
  renewPlanFlag: boolean = false;
  gymPlanStatus: boolean =false;

  constructor(
    public router: Router,
    private authService: AuthService,
    public subExpiredService: SubExpiredService
  ) { }

  ngOnInit() {
    if (this.InputObj.pendingBalance > 0) this.renewPlan = false;
  }

  goRenewPlan() {;
    this.router.navigate(['/services/features-plan/crm']);
    this.gymPlanStatus = true
  }


  closeDialog(flag) {
    this.OutputObj1.emit({ flag: flag, page: 'subExpiredPopUp' });
  }

  payBalance() {
    this.loader = true;
    let postObj = {
      adminPlanId: this.InputObj.adminPlanId,
      paidAmount: this.InputObj.pendingBalance
    }
    this.subExpiredService.payAllPartialPayment(postObj).subscribe((res) => {
      if (res.statusCode == "001") {
        this.paydata = res.paytmData;
        this.url = res.url;
        this.loader = false;
        this.onlinePayFlag = true;
      } else if (res.statusCode == "002") {
        this.loader = false;
      }
    });
  }

  exit() {
    this.authService.logout();
  }

  onSubmit() {
    this.loader = false;
  }
}
