import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalApiService } from 'src/app/shared-modules/global-api.service';
import { AuthService } from 'src/app/shared-modules/services/auth.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class GlobeSearchService {
  constructor(
    private http: HttpClient,
    private globalApiService: GlobalApiService,
    private authService: AuthService) { }

  globeSearch(postBody): Observable<any> {
    return this.http.post(this.globalApiService.getApiUrl() + '/branch/checkUserByMobileNumber', postBody)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }

  globeSearchSecond(postBody): Observable<any> {
    return this.http.post(this.globalApiService.getApiUrl() + '/adminSales/globalSearch', postBody)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }
}    