import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  HostListener
} from '@angular/core';
import { SideNavService } from './side-nav.service';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '../../shared-modules/services/auth.service';
import {
  Router,
  NavigationEnd,
  NavigationStart,
  NavigationCancel
} from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { GlobalApiService } from 'src/app/shared-modules/global-api.service';
import { SocketService } from 'src/app/shared-modules/services/chat.service';
import { Event } from '../../models/side-nav.data';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'fyt-side-nav',
  templateUrl: 'side-nav.component.html',
  styleUrls: ['side-nav.component.css'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class SideNavComponent implements OnDestroy, AfterViewInit {
  @ViewChild('snav', { static: false }) snav: MatSidenav;
  mobileQuery: MediaQueryList;
  fillerNav: any = [];
  dynamicNav: any = [];
  private _mobileQueryListener: () => void;
  isDynamicNav: boolean;
  iconUrl = '../../../assets/icons/sidenavIcons/';
  expanded: boolean;
  userData: any;
  brandName: any;
  centreName: any;
  defaultSettingModal: boolean;
  isNavLoading: boolean;
  loaderType: string;
  showSearch: any = {};
  searchInput: any;
  userName: any;
  profilePic: any;
  role: any;
  socConStat: any = false;
  minlength: any = 10;
  maxlength: any = 10;
  socket: any;
  userDetails: any;
  sendObj: any = {};
  ioConnection: any;
  popupFlag: boolean = false;
  onAction: any;
  localData: any;
  esslExist: boolean = false;
  restriction: any;
  attendanceArray: any = [];
  connected: boolean = false;
  RETRY_INTERVAL: any = 10000;
  timeout;
  globalNotifyObj: any = {};
  smsExpiryFlag: boolean = false;
  settingsData: any;
  subExpiredPupUp: boolean;
  myInputObj: any = {};
  currentActive: any = 0; // index of currently active sidenav
  brandLogo: any;
  restrictionFlag: boolean = false;
  restrictionObj: any = {};
  settingsRestrictions: any = [];
  openDoorPopupFlag: boolean = false;
  private readonly notifier: NotifierService;
  doorStatus: boolean = false;
  volume: any = "volume";
  openSubscriptionPop: boolean = false;
  contactDrawerFlag: boolean = false;
  @ViewChild('customTemplate', { static: true }) customNotificationTmpl;
  accessList: any = [];
  notification = 0;
  adminPlanName = "";

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private sideNavService: SideNavService,
    private authService: AuthService,
    private router: Router,
    public sanitizer: DomSanitizer,
    public socketService: SocketService,
    public globalApiService: GlobalApiService,
    // public subscription: Subscription,
    private iconRegistry: MatIconRegistry,
    public notifierService: NotifierService) {
    this.notifier = notifierService;
    this.mobileQuery = media.matchMedia('(max-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    let esslAvailabel;
    let eData = JSON.parse(localStorage.getItem('userDetails'));
    if (eData) esslAvailabel = eData.esslSrNo
    if (esslAvailabel) {
      setInterval(() => { this.checkDoorConnetion(); }, 60000); // for check door connection after every 60 seconds
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.checkAccess();
  }

  checkDoorConnetion() {
    let postObj = {};
    this.sideNavService.doorConnection(postObj).subscribe(res => {
      if (res.statusCode == '001') {
        this.doorStatus = true;
      } else {
        this.doorStatus = false;
      }
    })
  }

  ngAfterViewInit() {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.isNavLoading = true;
        }
        else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel
        ) {
          this.isNavLoading = false;
          this.onSearchCardAction();
        }
      });
  }

  ngOnInit() {
    this.checkAccess()
    this.checkSmsStatus();
    this.settingsRestrictions = localStorage.getItem('settings') ? JSON.parse(localStorage.getItem('settings')) : [];
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.adminPlanName = this.userDetails.adminPlanName
    this.refreshData();
    this.myInputObj = {
      'daysRemaining': this.userDetails.daysRemaining,
      'discount': this.userDetails.discount,
      'pendingBalance': this.userDetails.fytPlanPendingBalance,
      'dueDate': this.userDetails.fytPlanDueDate,
      'adminPlanId': this.userDetails.adminPlanId
    }

    this.subExpiredPupUp = this.userDetails.showPopup;
    this.onSocketInit();
    this.emmitFun();
    this.updateEssl();
    this.getSettings();
    this.newUpdateCount()
    // sound setting check
    let soundSett = localStorage.getItem('soundSetting');

    this.volume = soundSett === "false" ? "m_sound_mute.png" : "m_sound.png"
    // console.log("soundSett", soundSett);
    this.checkRoute(this.router);
  }

  checkAccess() {
    this.sideNavService.checkAccess({}).subscribe(res => {
      if (res.statusCode == '001') {
        this.accessList = res.data;
        localStorage.setItem("accessList", JSON.stringify(this.accessList));
      }
    });
  }

  checkRoute(navItem) {
    this.openSubscriptionPop = false;
    if ((this.accessList).includes(navItem.url)) this.openSubscriptionPop = true;
  }

  toShowNotification(type, message) {
    this.notifier.show({
      type: type,
      message: message
    });
  }

  // for socket
  onSocketInit() {
    this.socketService.initializeSocketIO();
    this.ioConnection = this.socketService.getMessages().subscribe((message) => { this.getDataForPopup(message); });
    // on connect and disconnetct
    this.socketService.onEvent(Event.CONNECT).subscribe(() => { this.emmitFun(); });
    this.socketService.onEvent(Event.DISCONNECT).subscribe(() => { });
    this.socketService.getNotifications().subscribe((data) => { this.toShowNotification(data.type, data.message) });
  }

  updateEssl() {
    let userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.localData = JSON.parse(localStorage.getItem('userDetails'));
    this.esslExist = userDetails.esslSrNo ? true : false;
    this.restriction = userDetails.componentAccessRestriction;
  }

  emmitFun() {
    this.socketService.notificationConnection({
      from: this.userDetails.name,
      content: localStorage.getItem('userDetails')
    });
  }

  getDataForPopup(data) {
    let notType = data.isActive ? "active" : "expired";
    // console.log(data.plans[0].pendingAmount > 0 && new Date(data.plans[0].dueDate) < new Date(), data.plans[0].pendingAmount, data.plans[0].dueDate);
    if (notType !== 'expired' && data.plans[0].pendingAmount > 0 && new Date(data.plans[0].dueDate) < new Date()) {
      notType = "newPending"
    }
    // console.log("notType", notType);
    // let notType2 = data.isActive ? "active" : "newPending";
    this.playAudio(notType);
    this.attendanceArray.push(data);
    this.sendObj = data;
    setTimeout(() => { this.attendanceArray.pop() }, 15000);
  }

  playAudio(type) {
    let soundSett = localStorage.getItem('soundSetting');
    if (soundSett !== "false") {
      let audio = new Audio();
      audio.src = `../../../assets/sounds/${type}.wav`;
      // console.log("soundSett", audio.src);
      audio.load();
      audio.play();
    }
  }

  //open door
  openDoor() {
    if (!this.doorStatus) {
      this.notifier.show({
        type: 'error',
        message: "Biometric device is disconnected, please check your biometric device's connection."
      });
      this.openDoorPopupFlag = false;
    } else {
      this.openDoorPopupFlag = true;
    }
  }

  openDoorFinal() {
    const postObj = {};
    this.sideNavService.openDoor(postObj).subscribe(res => {
      if (res.statusCode == '001') {
        this.closeOpenDoorpopup();
      }
    });
  }

  closeOpenDoorpopup() {
    this.openDoorPopupFlag = false;
  }

  refreshData() {
    this.getNav();
    this.setDefaultBranch();
  }

  setDefaultBranch() {
    this.userData = this.authService.loadUserData();
    let puData = JSON.parse(this.userData);
    this.brandLogo = puData.logo;
    this.userName = puData.name;
    this.profilePic = puData.profilePic;
    this.role = puData.role;
    let defaultSetting = puData.defaultSetting;
    this.brandName = defaultSetting?.defaultCentreName || "-";
    this.centreName = defaultSetting?.defaultBranchName || "-";
  }

  // to get the side nav from Database
  getNav() {
    this.sideNavService.getsideNav().subscribe(res => {
      this.dynamicNav = [];
      if (res && res.Status == 'Success') {
        if (res.data && res.data.length > 0) {
          this.dynamicNav = res.data;
        }
      }
    });
  }

  // get settings data
  getSettings() {
    this.sideNavService.accessSettings({}).subscribe(res => {
      if (res && res.Status == 'Success') {
        this.settingsData = res.data;
        // console.log(" this.settingsData", this.settingsData);

        localStorage.setItem("settings", JSON.stringify(this.settingsData));
      }
    });
  }

  checkSmsStatus() {
    this.sideNavService.smsLimitFunction({}).subscribe(res => {
      if (res && res.Status == 'Success') {
        this.globalNotifyObj.message = res.data.message;
        this.globalNotifyObj.secondsToDisplay = res.data.secondsToDisplay;
        this.globalNotifyObj.type = res.data.type;
        this.smsExpiryFlag = true;
      }
    });
  }

  // for nav Icons
  getIconForNav(name, imgUrl) {
    this.iconRegistry.addSvgIcon(name, this.sanitizer.bypassSecurityTrustResourceUrl(this.iconUrl + imgUrl));
    return name;
  }

  // to logOut
  logOutUser() {
    this.authService.logout();
  }

  // to navigate
  navigateTo(navItem, index) {
    this.checkRoute(navItem);
    this.currentActive = index;
    if (navItem.children && navItem.children.length > 0) this.expanded = !this.expanded;
    if (this.mobileQuery.matches) this.snav.close();
  }

  showMyProfile() {
    this.router.navigate(['/gym/profile']);
  }

  soundOnOff() {
    let soundSett = localStorage.getItem('soundSetting');
    let keyToStore = (soundSett === "false") ? "true" : "false";
    this.volume = keyToStore === "false" ? "m_sound_mute.png" : "m_sound.png";
    localStorage.setItem('soundSetting', keyToStore);
  }

  supportTo() {
    localStorage.setItem('back_url', this.router.url);
    this.router.navigate(['/gym/support']);
  }

  // to open the details
  onModalAction(event) {
    this.defaultSettingModal = true;
  }

  onCardAction(event) {
    this.defaultSettingModal = false;
    this.restrictionFlag = false;
  }

  openGlobeSearchBox(event?: any) {
    if (!event) return;
    this.showSearch.isOpen = true;
    this.showSearch.boxClass = 'slideInDown';
  }

  onSearchCardAction(event?: any) {
    this.showSearch.boxClass = 'slideOutUp';
    setTimeout(() => {
      this.showSearch.isOpen = false;
      this.searchInput = '';
    }, 1000);
  }

  onNotifyCardAction(event?: any) {
    this.smsExpiryFlag = false;
    this.attendanceArray.pop();
  }

  // for key escape event binding
  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.onSearchCardAction();
  }


  dynamicLength(searchInput1) {
    if (isNaN(this.searchInput)) {
      this.minlength = 1;
      this.maxlength = 25;
    } else {
      this.minlength = 10;
      this.maxlength = 10;
    }
  }

  onCancelCard(event) {
    if (event.flag == true) {
      this.refreshData();
    }
    this.subExpiredPupUp = false;
  }

  restrict() {
    this.restrictionFlag = true;
  }

  goToSettings() {
    let localDetails = JSON.parse(localStorage.getItem("userDetails"));
    let branchDetails = localDetails.defaultSetting;
    this.router.navigate([`/gym/branches/branch/${branchDetails.defaultBranchId}/settingFlag`]);
  }

  goToNotification() {
    this.router.navigate(['/gym/communication/notifications']);
  }

  goToNewTab() {
    let currentUrl = window.location.href.split("#");
    // console.log("window.open(`/#${currentUrl[1]}`);", currentUrl);
    window.open(`/#${currentUrl[1]}`);
  }

  openContactView() {
    this.contactDrawerFlag = this.contactDrawerFlag ? false : true;
  }

  newUpdates() {
    // console.log(event, "event")
    if (this.router.navigate(['/gym/support'])) localStorage.setItem('accountsTabIndex', '2');
  }

  newUpdateCount() {
    this.sideNavService.getUpdates().subscribe(res => {
      if (res && res.Status == 'Success') {
        this.notification = res.data.count ? res.data.count : 0
      }
    });
  }
}
