import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, PreloadAllModules } from '@angular/router';

import { SideNavComponent } from 'src/app/components/side-nav/side-nav.component';
import { AuthGuard } from './shared-modules/guards/auth.guard';
import { SidebarComponent } from './admin-components/sidebar/sidebar.component';
// import { CorporateNavbarComponent } from './corporate/corporate-navbar/corporate-navbar.component';
import { WebModule } from './web-portal/web/web.module';

const routes: Routes = [
  // without Authgaurd
  // {
  //   path: 'login',
  //   loadChildren: () => import('../app/components/login/login.module').then(m => m.LoginModule)
  // },
  // {
  //   path: 'login',
  //   loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule)
  // },
  {
    path: 'login',
    loadChildren: () => import('src/app/components/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'valentine',
    loadChildren: () => import('src/app/components/gym-marketing/gym-marketing.module').then(m => m.GymMarketingModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('src/app/components/gym-marketing/gym-marketing.module').then(m => m.GymMarketingModule)
  },
  {
    path: 'services-restriction',
    loadChildren: () => import('src/app/components/services-popup/services-popup.module').then(m => m.ServicesPopupModule)
  },
  {
    path: 'external',
    loadChildren: () => import('src/app/components/external-link/external-link.module').then(m => m.ExternalLinkModule)
  },
  {
    path: 'fitness-centers',
    loadChildren: () => import('src/app/web-portal/web/web.module').then(m => m.WebModule)
  },
  {
    path: 'simple-workout/:classId',
    loadChildren: () => import('src/app/components/simple-workout/simple-workout.module').then(m => m.SimpleWorkoutModule)
  },
  {
    path: 'online-fitness-classes',
    loadChildren: () => import('src/app/components/desktop/desktop.module').then(m => m.DesktopModule)
  },
  {
    path: 'new-feature',
    loadChildren: () => import('src/app/components/new-feature/new-feature.module').then(m => m.NewFeatureModule)
  },
  {
    path: 'events/:branchId',
    loadChildren: () => import('src/app/components/sports/sports.module').then(m => m.SportsModule)
  },
  {
    path: 'billing',
    loadChildren: () => import('src/app/components/billing/billing.module').then(m => m.BillingModule)
  },
  {
    path: 'book-date',
    loadChildren: () => import('src/app/components/booking-sports/booking-sports.module').then(m => m.BookingSportsModule)
  },
  {
    path: 'register',
    loadChildren: () => import('src/app/components/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'quickReg',
    loadChildren: () => import('src/app/components/social-media/social-media.module').then(m => m.SocialMediaModule)
  },
  {
    path: 'forgetpassword',
    loadChildren: () => import('src/app/components/forgetpassword/forgetpassword.module').then(m => m.ForgetpasswordModule)
  },
  {
    path: 'admin-login',
    loadChildren: () => import('src/app/admin-components/admin-login/admin-login.module').then(m => m.AdminLoginModule)
  },
  {
    path: 'assignTo',
    loadChildren: () => import('src/app/components/assign-staff/assign-staff.module').then(m => m.AssignStaffModule)
  },


  // {
  //   path:'corp',
  //   component:CorporateNavbarComponent,
  //   children:[
  //     {
  //       path: '',
  //       pathMatch: 'full',
  //       redirectTo: 'corp-home'
  //     },
  //     {
  //       path:'corp-home',
  //       loadChildren:'src/app/corporate/corporate-home/corporate-home.module#CorporateHomeModule'
  //     },
  //     {
  //       path:'about',
  //       loadChildren:'src/app/corporate/about-us/about-us.module#AboutUsModule'
  //     },
  //     {
  //       path:'contact',
  //       loadChildren:'src/app/corporate/contact-us/contact-us.module#ContactUsModule'
  //     }asd
  //   ]
  // },


  {
    path: 'services',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'sms-plan',
        loadChildren: () => import('src/app/components/sms-plan/sms-plan.module').then(m => m.SmsPlanModule)
      },
      {
        path: 'features-plan/:planType',
        loadChildren: () => import('src/app/components/features-plan/features-plan.module').then(m => m.FeaturesPlanModule)
      },
      {
        path: 'features-plan/:adminUserId/:centreId/:centreName',
        loadChildren: () => import('src/app/components/features-plan/features-plan.module').then(m => m.FeaturesPlanModule)
      },
      {
        path: 'adminLead',
        loadChildren: () => import('src/app/admin-components/admin-lead/admin-lead.module').then(m => m.AdminLeadModule)
      }
    ]
  },

  {
    path: 'gym',
    component: SideNavComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('src/app/components/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'members',
        loadChildren: () => import('src/app/components/members/member.module').then(m => m.MemberModule)
      },
      {
        path: 'batches',
        loadChildren: () => import('src/app/components/batches/batches.module').then(m => m.BatchesModule)
      },
      {
        path: 'accounts',
        loadChildren: () => import('src/app/components/accounts/accounts.module').then(m => m.AccountsModule)
      },
      {
        path: 'enquiry',
        loadChildren: () => import('src/app/components/enquiry/enquiry.module').then(m => m.EnquiryModule)
      },
      {
        path: 'branches',
        loadChildren: () => import('src/app/components/branches/branches.module').then(m => m.BranchesModule)
      },
      {
        path: 'plans',
        loadChildren: () => import('src/app/components/plans/plans.module').then(m => m.PlansModule)
      },
      {
        path: 'schedule',
        loadChildren: () => import('src/app/components/schedule/schedule.module').then(m => m.ScheduleModule)
      },
      {
        path: 'communication',
        loadChildren: () => import('src/app/components/notifications/notification.module').then(m => m.NotificationModule)
      },
      {
        path: 'attendance',
        loadChildren: () => import('src/app/components/attendance/attendance.module').then(m => m.AttendanceModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('src/app/components/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'staff',
        loadChildren: () => import('src/app/components/staff/staff.module').then(m => m.StaffModule)
      },
      {
        path: 'sale',
        loadChildren: () => import('src/app/components/sale/sale.module').then(m => m.SaleModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('src/app/components/reports/reports.module').then(m => m.ReportsModule)
      },
      // for create center
      {
        path: 'create-center',
        loadChildren: () => import('src/app/components/staff/staff.module').then(m => m.StaffModule)
      },
      {
        path: 'camera',
        loadChildren: () => import('src/app/components/camera/camera.module').then(m => m.CameraModule)
      },
      {
        path: 'equipements',
        loadChildren: () => import('src/app/components/equipments/equipments.module').then(m => m.EquipmentsModule)
      },
      {
        path: 'calender-booking',
        loadChildren: () => import('src/app/components/calender-booking/calender-booking.module').then(m => m.CalenderBookingModule)
      },
      {
        path: 'pay-sms',
        loadChildren: () => import('src/app/components/pay-new/pay-new.module').then(m => m.PayNewModule)
      },
      {
        path: 'support',
        loadChildren: () => import('src/app/components/support/support.module').then(m => m.SupportModule)
      },
      {
        path: 'online-training',
        loadChildren: () => import('src/app/components/online-training/online-training.module').then(m => m.OnlineTrainingModule)
      },
      {
        path: 'event',
        loadChildren: () => import('src/app/components/event/event.module').then(m => m.EventModule)
      }
    ]
  },
  {
    path: 'admin',
    component: SidebarComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'enq',
        loadChildren: () => import('src/app/admin-components/enqlist/enqlist.module').then(m => m.EnqlistModule)
      },
      {
        path: 'reg',
        loadChildren: () => import('src/app/admin-components/registrations/registrations.module').then(m => m.RegistrationsModule)
      },
      {
        path: 'centre',
        loadChildren: () => import('src/app/admin-components/centre/centre.module').then(m => m.CentreModule)
      },
      {
        path: 'sales',
        loadChildren: () => import('src/app/admin-components/admin-sales/admin-sales.module').then(m => m.AdminSalesModule)
      },
      {
        path: 'db',
        loadChildren: () => import('src/app/admin-components/db/db.module').then(m => m.DbModule)
      },
      {
        path: 'mailService',
        loadChildren: () => import('src/app/admin-components/communications/communications.module').then(m => m.CommunicationsModule)
      },
      {
        path: 'userTree',
        loadChildren: () => import('src/app/admin-components/tree-user/tree-user.module').then(m => m.TreeUserModule)
      },
      {
        path: 'dash',
        loadChildren: () => import('src/app/admin-components/dash/dash.module').then(m => m.DashModule)
      },
      {
        path: 'lead',
        loadChildren: () => import('src/app/admin-components/lead/lead.module').then(m => m.LeadModule)
      },
      {
        path: 'wyhLead',
        loadChildren: () => import('src/app/admin-components/wyh-lead/wyh-lead.module').then(m => m.WyhLeadModule)
      },
      {
        path: 'offerlead',
        loadChildren: () => import('src/app/admin-components/lead-offer/lead-offer.module').then(m => m.LeadOfferModule)
      },
      {
        path: 'faq',
        loadChildren: () => import('src/app/admin-components/faq/faq.module').then(m => m.FaqModule)
      },
      {
        path: 'subscriptions',
        loadChildren: () => import('src/app/admin-components/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule)
      },
      {
        path: 'issue',
        loadChildren: () => import('src/app/admin-components/issue/issue.module').then(m => m.IssueModule)
      },
      {
        path: 'products',
        loadChildren: () => import('src/app/admin-components/products/products.module').then(m => m.ProductsModule)
      },
      {
        path: 'online-classes',
        loadChildren: () => import('src/app/admin-components/online-classes/online-classes.module').then(m => m.OnlineClassesModule)
      },
      {
        path: 'adminTrainers',
        loadChildren: () => import('src/app/admin-components/admin-trainers/admin-trainers.module').then(m => m.AdminTrainersModule)
      }, {
        path: 'adminStaff',
        loadChildren: () => import('src/app/admin-components/admin-staff/admin-staff.module').then(m => m.AdminStaffModule)
      },
      {
        path: 'adminLead',
        loadChildren: () => import('src/app/admin-components/admin-lead/admin-lead.module').then(m => m.AdminLeadModule)
      },
      {
        path: 'report',
        loadChildren: () => import('src/app/admin-components/report/report.module').then(m => m.ReportModule)
      },
      {
        path: 'excelUploader',
        loadChildren: () => import('src/app/admin-components/excel-uploader/excel-uploader.module').then(m => m.ExcelUploaderModule)
      },
      {
        path: 'salesPanelReport',
        loadChildren: () => import('src/app/admin-components/sales-panel-report/sales-panel-report.module').then(m => m.SalesPanelReportModule)
      },
      {
        path: "salesTab",
        loadChildren: () => import('src/app/admin-components/sales-tab/sales-tab.module').then(m => m.SalesTabModule)
      },
      {
        path: 'newCalendar',
        loadChildren: () => import('src/app/admin-components/new-calendar/new-calendar.module').then(m => m.NewCalendarModule)
      }
    ]
  },

  // default and error routes
  { path: '', redirectTo: 'external', pathMatch: 'full' },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  // { path: '**', redirectTo: 'external' }
];

// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })

export const appRoutingProviders: any[] = [];
// export const routing: ModuleWithProviders<WebModule> = RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' });
// export class AppRoutingModule { }

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}