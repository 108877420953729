import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssignStaffComponent } from './assign-staff/assign-staff.component';
import { AngularMaterialModule } from 'src/app/shared-modules/angular.material.module';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";


@NgModule({
  declarations: [AssignStaffComponent],
  imports: [
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule
  ], exports: [AssignStaffComponent]
})
export class AssignStaffModule { }
