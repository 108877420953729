import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalApiService } from 'src/app/shared-modules/global-api.service';
import { AuthService } from 'src/app/shared-modules/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SubExpiredService {

  constructor(
    private http: HttpClient,
    private globalApiService: GlobalApiService,
    private authService: AuthService
  ) { }

  payAllPartialPayment(postObj): Observable<any> {
    return this.http.post(this.globalApiService.getApiUrl() + '/paytm/partial', postObj)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }

 

  buyPlansDetails(postObj): Observable<any> {
    return this.http.post(this.globalApiService.getApiUrl() + '/paytm/pay', postObj)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }

  offlinePayment(postObj): Observable<any> {
    const formData: FormData = new FormData();
    if (postObj.file) {
      formData.append('file', postObj.file, postObj.file.name);
    }
    formData.append('planData', JSON.stringify(postObj));
    return this.http.post(this.globalApiService.getApiUrl() + '/adminPlans/adminPaymentManualProfilepage', formData)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }
  partialOfflinePayment(postObj): Observable<any> {
    const formData: FormData = new FormData();
    if (postObj.file) {
      formData.append('file', postObj.file, postObj.file.name);
    }
    formData.append('planData', JSON.stringify(postObj));
    return this.http.post(this.globalApiService.getApiUrl() + '/adminPlans/profileOfflinePartialEntry', formData)
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }
  
  renewOfflinePayment(postObj): Observable<any> {
    const formData: FormData = new FormData();
    if (postObj.file) {
        formData.append('file', postObj.file, postObj.file.name);
    }
    formData.append('planData', JSON.stringify(postObj));
    return this.http.post(this.globalApiService.getApiUrl() + '/adminPlans/adminPaymentManual', formData)
        .pipe(catchError((e: any) => this.authService.getCheckError(e)));
}

}
