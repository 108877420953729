import { NgModule } from '@angular/core';

import { GhostLoaderComponent } from './loader/ghost-loader.component';
import { AngularMaterialModule } from 'src/app/shared-modules/angular.material.module';

@NgModule({
    imports: [AngularMaterialModule],
    exports: [GhostLoaderComponent],
    declarations: [GhostLoaderComponent],
    providers: [],
})
export class GhostLoaderModule { }