import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RenewPlanRoutingModule } from './renew-plan-routing.module';
import { RenewPlanComponent } from './renew-plan/renew-plan.component';
import { AngularMaterialModule } from 'src/app/shared-modules/angular.material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatNativeDateModule } from '@angular/material/core';
import { RenewPlanService } from './renew-plan.service';
import { CameraModule } from '../camera/camera.module';


@NgModule({
  declarations: [
    RenewPlanComponent
  ],
  imports: [
    CommonModule,
    RenewPlanRoutingModule,
    AngularMaterialModule,
    FormsModule,
    CameraModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatMomentDateModule
  ],
  exports: [RenewPlanComponent],
  providers: [RenewPlanService]
})
export class RenewPlanModule { }
