import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobeNotifyComponent } from './globe-notify/globe-notify.component';
import { GlobeNotifyService } from './globe-notify.service';
import { AngularMaterialModule } from 'src/app/shared-modules/angular.material.module';


@NgModule({
  declarations: [GlobeNotifyComponent],
  imports: [
    CommonModule,
    AngularMaterialModule
  ],
  providers:[GlobeNotifyService],
  exports:[GlobeNotifyComponent]
})
export class GlobeNotifyModule { }
