import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GlobalApiService } from '../../shared-modules/global-api.service';
import { AuthService } from '../../shared-modules/services/auth.service'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  constructor(
    private http: HttpClient,
    private globalApiService: GlobalApiService,
    private authService: AuthService) { }

  // for sidenav from database
  getsideNav(): Observable<any> {
    return this.http.get(this.globalApiService.getApiUrl() + '/structure/getAdminSideNav')
      .pipe(catchError((e: any) => this.authService.getCheckError(e)));
  }
}