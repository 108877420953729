export const dynamicNav = [
  {
    name: "Dashboard",
    url: "/",
    imgUrl: "../../../assets/images/1p1202.png",
    titleClass: "ml-1",
    imgClass: "",
    icon: "dashboardIcon"
  },
  {
    name: "Enquiry",
    url: "/enquiry",
    imgUrl: "../../../assets/images/1p1203.png",
    titleClass: "ml-1",
    imgClass: "",
    icon: "enquiryIcon"
  },
  {
    name: "Members",
    url: "/members",
    imgUrl: "../../../assets/images/1p1204.png",
    titleClass: "ml-1",
    imgClass: "",
    icon: "membersIcon"
  },
  {
    name: "Attendance",
    url: "/attendance",
    imgUrl: "../../../assets/images/1p1205.png",
    titleClass: "ml-1",
    imgClass: "",
    icon: "attendanceIcon"
  },
  {
    name: "Plans",
    url: "/plans",
    imgUrl: "../../../assets/images/1p1206.png",
    titleClass: "ml-1",
    imgClass: "",
    icon: "lansIcon"
  },
  {
    name: "Staff",
    url: "/staff",
    imgUrl: "../../../assets/images/1p1207.png",
    titleClass: "ml-1",
    imgClass: "",
    icon: "staffIcon",
    children: [
      {
        name: "Trainer",
        url: "/staff/trainers",
        titleClass: "ml-1",
        icon: "staffIcon"
      },
      {
        name: "Others",
        url: "/staff/others",
        titleClass: "ml-1",
        icon: "staffIcon"
      }
    ]
  },
  {
    name: "Notifications",
    url: "/notifications",
    imgUrl: "../../../assets/images/1p1208.png",
    titleClass: "ml-1",
    imgClass: "",
    icon: "notificationsIcon"
  },
  {
    name: "Accounts",
    url: "/acounts",
    imgUrl: "../../../assets/images/1p1209.png",
    titleClass: "ml-1",
    imgClass: "",
    icon: "accountsIcon"
  },
  {
    name: "Branches",
    url: "/branches",
    imgUrl: "../../../assets/images/1p12010.png",
    titleClass: "ml-1",
    imgClass: "",
    icon: "branchesIcon"
  }
];

export const FilterBtns = [
  { name: 'Previous', value: 'lastThirtyDays' },
  { name: 'Today', value: 'today' },
  { name: 'Next', value: 'nextThirtyDays' }
];

export const FiltersItems = [
  { name: 'Enquiry', value: 'enquiry', count: 0, class: '', pipe: '', filters: {} },
  { name: 'New admition', value: 'enquiry', count: 0, class: '', pipe: '', filters: {} },
  { name: 'Due for renewal this week', value: 'members', class: '', pipe: '', count: 0, filters: {} },
  { name: 'Membership expired', value: 'members', class: '', pipe: '', count: 0, filters: {} },
  { name: 'Renewal', value: 'plans', count: 0, class: '', pipe: '', filters: {} },
  { name: 'Collection', value: 'acounts', count: '0', class: 'fyt-credit', pipe: 'currency', filters: {} },
  { name: 'Billings', value: 'acounts', count: '0', class: 'fyt-debit', pipe: 'currency', filters: {} }
]

export const AdminEnqFiltersItems = [
  { name: 'New Enquiry Generated', value: 'enq', count: 0, class: '', pipe: '', filters: {} },
  { name: 'Pending Follow Up', value: 'enq', count: 0, class: '', pipe: '', filters: {} },
  { name: 'Enquiry updated', value: 'enq', class: '', pipe: '', count: 0, filters: {} }
]

export const Attendance = [
  { name: 'M', count: 0, filters: {} },
  { name: 'T', count: 0, filters: {} },
  { name: 'W', count: 0, filters: {} },
  { name: 'T', count: 0, filters: {} },
  { name: 'F', count: 0, filters: {} },
  { name: 'S', count: 0, filters: {} },
  { name: 'S', count: 0, filters: {} }
]
export const postObj = {
  center: {},
  timing: {},
  address: {},
  service: {},
  plan: {}
}

export enum Action {
  JOINED,
  LEFT,
  RENAME
}

// Socket.io events
export enum Event {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect'
}
