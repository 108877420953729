import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotifyPopupComponent } from './notify-popup/notify-popup.component';
import { AngularMaterialModule } from 'src/app/shared-modules/angular.material.module';



@NgModule({
  declarations: [NotifyPopupComponent],
  imports: [
    CommonModule,
    AngularMaterialModule
  ],
  exports: [NotifyPopupComponent]
})
export class FytNotifyPopupModule { }
